import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";

import { VisualizationPage } from "./pages/Visualization";

exportingModule(Highcharts);

function App() {
  return (
    <div className="m-auto w-full">
      <VisualizationPage />
    </div>
  );
}

export default App;
