import { ChangeEvent, FC } from "react";
import { VisualizationField } from "../type";
import { DragButton } from "./DragButton";
import { Divider } from "../../Divider";

type SidebarProps = {
  search: string;
  setSearch: (search: string) => void;
  allFields: VisualizationField[];
  searchedFields: VisualizationField[];
  setSearchedFields: (fields: VisualizationField[]) => void;
};

export const Sidebar: FC<SidebarProps> = ({
  search,
  setSearch,
  allFields,
  searchedFields,
  setSearchedFields,
}) => {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);

    // NOTE: Reset the fields if the search input is empty
    if (value === "") return setSearchedFields(allFields);

    // NOTE: Filter the fields based on the search input
    const filteredFields = allFields.filter((field) =>
      field.key.toLowerCase().includes(value.toLowerCase())
    );

    // NOTE: Update the searched fields
    setSearchedFields(filteredFields);
  };

  return (
    <div className="w-64 p-4 border-2 border-zinc-400 rounded-xl">
      <div className="flex flex-col items-start gap-3">
        <div className="w-full">
          <h1>Attributes</h1>

          <Divider className="my-1" />
        </div>

        <input
          type="text"
          placeholder="Search..."
          className="border border-zinc-400 rounded-md p-2"
          value={search}
          onChange={handleSearch}
        />

        <div className="flex flex-col gap-2 items-start w-full">
          {searchedFields.map(({ key, type }) => (
            <DragButton key={key} dragKey={key} type={type} />
          ))}
        </div>
      </div>
    </div>
  );
};
