import { FC, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { FiChevronDown } from "react-icons/fi";

import { Draggable } from "../../dnd/Draggable";
import { SortableItem } from "../../dnd/SortableItem";
import { VisualizationAggregateType, VisualizationValueType } from "../type";
import { useClickOutside } from "../../../hooks/useClickOutside";

type DragButtonProps = {
  dragKey: string;
  type: VisualizationValueType;
  aggregateType?: VisualizationAggregateType;
  width?: "fit" | "full";
  hasSelectAggregate?: boolean;
  onChangeAggregate?: (axisKey: string, aggregateType: string) => void;
};

const aggregateOptions = [
  { label: "COUNT", value: "count" },
  { label: "SUM", value: "sum", numberOnly: true },
  { label: "MIN", value: "min", numberOnly: true },
  { label: "MAX", value: "max", numberOnly: true },
  { label: "AVG", value: "avg", numberOnly: true },
] as {
  label: string;
  value: VisualizationAggregateType;
  numberOnly?: boolean;
}[];

export const DragButton: FC<DragButtonProps> = ({
  dragKey,
  type,
  aggregateType,
  width = "full",
  hasSelectAggregate,
  onChangeAggregate,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const [openAggregate, setOpenAggregate] = useState(false);
  const [aggregate, setAggregate] = useState<VisualizationAggregateType>(
    aggregateType || "count"
  );

  useClickOutside(popupRef, () => setOpenAggregate(false));

  const aggregateOptionsByType = useMemo(() => {
    if (type === "number") return aggregateOptions;

    return aggregateOptions.filter((option) => !option.numberOnly);
  }, [type]);

  const handleSelectAggregate = (value: VisualizationAggregateType) => {
    setAggregate(value);
    setOpenAggregate(false);
    onChangeAggregate?.(dragKey, value);
  };

  return (
    <SortableItem
      key={dragKey}
      id={dragKey}
      className={clsx("z-10", width === "fit" ? "w-auto" : "w-full")}
    >
      <Draggable
        key={dragKey}
        id={dragKey}
        className={clsx(
          "px-2 py-1 rounded-md text-start flex gap-2 items-center cursor-grab active:cursor-grabbing active:z-20",
          width === "fit" ? "w-auto" : "w-full",
          type === "number" ? "bg-sky-300" : "bg-green-300"
        )}
      >
        <span>{hasSelectAggregate ? `${aggregate}(${dragKey})` : dragKey}</span>

        {hasSelectAggregate && (
          <div
            ref={popupRef}
            className="relative bg-opacity-80 bg-sky-200 hover:bg-sky-200/40 rounded-md cursor-pointer"
          >
            <FiChevronDown
              onClick={() => setOpenAggregate(!openAggregate)}
              className={clsx(
                "text-lg text-gray-500 transition-all",
                openAggregate && "rotate-180"
              )}
            />

            {openAggregate && (
              <div className="absolute z-10 w-40 bg-white rounded-xl shadow-lg p-3 top-[calc(100%+16px)] border-zinc-200 border">
                <div className="flex flex-col">
                  {aggregateOptionsByType.map((option) => (
                    <span
                      key={option.value}
                      onClick={() => handleSelectAggregate(option.value)}
                      className="hover:bg-zinc-300 px-2 py-1 rounded-md"
                    >
                      {option.label}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
    </SortableItem>
  );
};
