import { useDroppable } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import clsx from "clsx";

type DroppableProps = {
  children: React.ReactNode;
  id: string;
  className?: string;
  overClassName?: string;
  items?: any[];
};

export const Droppable = ({
  children,
  id,
  className,
  overClassName = "bg-sky-200",
  items = [],
}: DroppableProps) => {
  const { isOver, setNodeRef } = useDroppable({ id });

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        "bg-zinc-300 rounded-lg",
        className,
        isOver && overClassName
      )}
      key={`${id}-drop`}
      id={`${id}-drop`}
    >
      <SortableContext items={items} key={`${id}-sort`} id={`${id}-sort`}>
        {children}
      </SortableContext>
    </div>
  );
};
