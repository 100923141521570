import useAxios from "axios-hooks";
import { Visualization } from "../components/visualization/Visualization";

import { useMemo } from "react";

export const VisualizationPage = () => {
  const currUrl = window.location.href;
  const resourceId = currUrl.split("/").pop();
  const [{ data, loading, error }] = useAxios(
    `${process.env.REACT_APP_BASE_API_URL}?resource_id=${resourceId}`
  );

  const { source, dict } = useMemo(() => {
    const fields = data?.result?.fields || [];
    const records = data?.result?.records || [];

    const dict = fields.map((field: any) => {
      return {
        attribute: field.id,
        type: field.type,
        desc: field?.description || "",
      };
    });

    return { source: records, dict };
  }, [data?.result]);

  if (loading) return <div>Loading...</div>;
  if (error) {
    console.error("error", error);

    return <div>Error!</div>;
  }

  return <Visualization dataSource={source} dataDict={dict} />;
};
