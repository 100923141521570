import { FC, useState } from "react";
import { ColumnChart } from "../../charts/ColumnChart";
import { BarChart } from "../../charts/BarChart";
import { LineChart } from "../../charts/LineChart";
import { AreaChart } from "../../charts/AreaChart";
import { PieChart } from "../../charts/PieChart";
import clsx from "clsx";
import { useChartDataSource } from "../hooks/useChartDataSource";
import { VisualizationChartType, VisualizationField } from "../type";
import { ChartCentreProps } from "../../charts/type";

type ChartProps = {
  dataSource: any[];
  xAxisOptions: VisualizationField[];
  yAxisOptions: VisualizationField[];
};

const chartTypeButtons = [
  { label: "Column", value: "column" },
  { label: "Bar", value: "bar" },
  { label: "Line", value: "line" },
  { label: "Area", value: "area" },
  { label: "Pie", value: "pie" },
] as { label: string; value: VisualizationChartType }[];

export const Chart: FC<ChartProps> = ({
  dataSource,
  xAxisOptions,
  yAxisOptions,
}) => {
  const [chartType, setChartType] = useState<VisualizationChartType>("column");

  const { categories, series } = useChartDataSource({
    chartType,
    dataSource,
    xAxisOptions,
    yAxisOptions,
  });

  const ChartComponent = {
    column: ColumnChart,
    bar: BarChart,
    line: LineChart,
    area: AreaChart,
    pie: PieChart,
  }[chartType];

  return (
    <div className="">
      <div className="flex gap-2 items-center justify-end">
        {chartTypeButtons.map((button) => (
          <button
            key={button.value}
            className={clsx(
              "px-2 py-1 rounded-lg",
              chartType === button.value ? "bg-green-400" : "bg-zinc-300"
            )}
            onClick={() => setChartType(button.value)}
          >
            {button.label}
          </button>
        ))}
      </div>

      <ChartComponent
        categories={categories}
        series={series as ChartCentreProps["series"]}
      />
    </div>
  );
};
