import React, { FC } from "react";

import { TabTable } from "./TabTable";
import { TabGraph } from "./TabGraph";

type VisualizationProps = {
  dataSource: any[];
  dataDict: any[];
};

export const Visualization: FC<VisualizationProps> = ({
  dataSource,
  dataDict,
}) => {
  const tabs = [
    {
      label: "Table",
      element: (
        <TabTable dataSource={dataSource} height="calc(100vh - 185px)" />
      ),
    },
    {
      label: "Graph",
      element: <TabGraph dataSource={dataSource} dataDict={dataDict} />,
    },
    // {
    //   label: "Map",
    //   element: <div>Map</div>,
    // },
  ];

  return (
    <div className="max-w-sm md:max-w-3xl lg:max-w-5xl m-auto flex flex-col p-6">
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold mb-6">Visualization</h1>

        <div role="tablist" className="tabs tabs-lifted">
          {tabs.map(({ element, label }, idx) => (
            <React.Fragment key={`${label}-${idx}`}>
              <input
                type="radio"
                name="visualization-tabs"
                role="tab"
                className="tab"
                aria-label={label}
                defaultChecked={idx === 1}
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                {element}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
