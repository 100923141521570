import { FC } from "react";

type TableColumn = {
  key: string;
  label?: string;
  type?: "string" | "number" | "date";
};

type TableProps = {
  columns: TableColumn[];
  dataSource: any[];
  height?: string | number;
  showIndex?: boolean;
};

export const Table: FC<TableProps> = ({
  columns,
  dataSource,
  height = "auto",
  showIndex,
}) => {
  return (
    <div className="overflow-x-auto rounded-lg bg-white" style={{ height }}>
      <table className="table table-xs table-pin-rows table-pin-cols">
        <thead>
          <tr>
            {showIndex && <th>_id</th>}

            {columns.map((column) => (
              <th key={column.key}>{column?.label || column.key}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {dataSource.map((data, idx) => (
            <tr key={idx} className="hover">
              {showIndex && <td>{idx + 1}</td>}

              {columns.map((column) => (
                <td key={column.key}>{data[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
