import { FC } from "react";
import {
  DndContext as DndCtx,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

type DndContextProps = {
  children: React.ReactNode;
  onDragEnd?: (event: any) => void;
};

export const DndContext: FC<DndContextProps> = ({ children, onDragEnd }) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      // NOTE: Solution to the issue of complex draggable components
      distance: 10, // NOTE: Enable sort function when dragging 10px
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <DndCtx sensors={sensors} onDragEnd={onDragEnd}>
      {children}
    </DndCtx>
  );
};
